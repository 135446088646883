import Button from '@atoms/Button/Button';
import { IconType } from '@atoms/Icon/Icon';
import { BG_ALTERNATIVE } from '@constants/constants';
import ContentCarouselSection from '@organisms/ContentCarouselSection/ContentCarouselSection';
import { OverviewItem } from '@type-declarations/overviewItems';
import clsx from 'clsx';
import { useState } from 'react';

import styles from './SegmentationSection.module.scss';

interface Props {
  segmentationFields: {
    id: string;
    title: string;
    text?: string;
    icon: IconType;
    items: OverviewItem[];
  }[];
}

export default function SegmentationSection({ segmentationFields }: Props) {
  const [activeIndex, setActiveIndex] = useState(0);

  function handleActiveSegment(index: number) {
    setActiveIndex(index);
  }

  return (
    <section className={styles.section}>
      <div className={styles.navContainer}>
        <div className={styles.navWrapper}>
          {segmentationFields.map(({ id, title, icon }, index) => (
            <div
              key={id}
              className={clsx(index === activeIndex && styles.active)}
            >
              <Button
                type="button"
                onClick={() => handleActiveSegment(index)}
                variant="segmentation"
                modifier="redIcon"
                target="_self"
                label={title}
                iconLeft={icon}
              />
            </div>
          ))}
        </div>
      </div>

      <ContentCarouselSection
        typename="ContentCarouselSection"
        id="segmentation-carousel-section"
        title={segmentationFields[activeIndex]?.title}
        text={segmentationFields[activeIndex]?.text}
        maxCount={10}
        backgroundColor={{ color: BG_ALTERNATIVE }}
        selection={segmentationFields[activeIndex]?.items}
      />
    </section>
  );
}
