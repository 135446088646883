import Hero from '@organisms/Hero/Hero';
import Sections from '@organisms/Sections/Sections';
import SegmentationSection from '@organisms/SegmentationSection/SegmentationSection';
import { HomePage } from '@type-declarations/page';

function HomeTemplate({ page }: { page: HomePage }) {
  const {
    title,
    introText,
    image,
    sections,
    cta,
    secondCta,
    alignment,
    segmentationFields,
  } = page;

  return (
    <>
      <Hero
        title={title}
        text={introText}
        image={image}
        video={{
          mobile: '/video/home-hero-mobile.mp4',
          desktop: '/video/home-hero-desktop.mp4',
        }}
        cta={cta}
        secondCta={secondCta}
        variant="large"
        colorVariant="brand"
        alignment={alignment}
      />
      {!!segmentationFields?.length && (
        <SegmentationSection segmentationFields={segmentationFields} />
      )}
      <Sections sections={sections} />
    </>
  );
}

export default HomeTemplate;
